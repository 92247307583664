import React from "react";
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js";
import { ellipsis, rgba } from "polished";
import Sticky from "react-sticky-el";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { Box, Icon, Illustration } from "src/components";

const ArrowLeft = ({ onClick, color, bg }) => (
  <Box
    bg={bg}
    css={`
      display: none;
      position: absolute;
      top: calc(50% - 16px);
      left: 24px;
      cursor: pointer;

      @media (max-width: 1024px) {
        display: block;
      }
    `}
  >
    <Icon
      path={mdiChevronLeft}
      bg={bg}
      color={color ? color : "#fff"}
      css={`
        width: 32px !important;
        height: 32px !important;
      `}
      onClick={onClick}
    />
  </Box>
);

const ArrowRight = ({ onClick, color, bg }) => (
  <Box
    bg={bg}
    css={`
      display: none;
      position: absolute;
      top: calc(50% - 16px);
      right: 24px;
      cursor: pointer;

      @media (max-width: 1024px) {
        display: block;
      }
    `}
  >
    <Icon
      path={mdiChevronRight}
      color={color ? color : "#fff"}
      onClick={onClick}
      css={`
        width: 32px !important;
        height: 32px !important;
      `}
    />
  </Box>
);

const Pill = ({
  heading,
  date,
  value,
  currentDay,
  setCurrentDay,
  illustrations,
  width,
  index,
}) => (
  <div
    onClick={() => {
      setCurrentDay(value);
    }}
    css={`
      position: relative;
      display: inline-block;
      overflow: hidden;
      width: ${width}%;
      min-width: 200px;
      padding: 16px 0;
      background-color: ${(props) => props.theme.colors.text.reverse};
      cursor: pointer;
      * {
        color: ${(props) => props.theme.colors.text.default} !important;
      }

      @media (min-width: 1024px) {
        &.schedule-header__active {
          background-color: ${(props) => props.theme.colors.brand.tertiary};
          * {
            color: ${(props) => props.theme.colors.text.reverse} !important;
          }
        }
      }

      @media (max-width: 1024px) {
        background-color: ${(props) => props.theme.colors.brand.tertiary};
        * {
          color: ${(props) => props.theme.colors.text.reverse} !important;
        }
        padding: 10px 0;
        pointer-events: none;
        display: inline-block;
        text-align: center;
        width: 100%;
      }
    `}
    className={
      value === currentDay ? "schedule-header__active" : "schedule-header"
    }
  >
    <div
      css={`
        position: absolute;
        top: 4px;
        right: -32px;
        width: 128px;

        @media (max-width: 1024px) {
          top: 0px;
          right: 24px;
        }
      `}
    >
      <Illustration number={index % 4} illustrations={illustrations} />
    </div>
    <div
      css={`
        position: relative;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        text-align: center;
        color: ${(props) => props.theme.colors.text.reverse};
      `}
    >
      {heading}
      <div
        css={`
          position: relative;
          margin-top: 4px;
          font-size: 12px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          color: ${(props) => rgba(props.theme.colors.text.reverse, 0.5)};
        `}
      >
        {date}
      </div>
    </div>
  </div>
);

const ColumnHeader = ({ heading, subheading, inactive }) => (
  <div
    className={inactive && "inactive"}
    css={`
      display: inline-block;
      width: calc(100vw - 64px - 48px);
      max-width: 200px;
      margin: 0 8px;
      padding-top: 6px;
      padding-left: 2px;
      padding-bottom: 6px;
      border-right: 1px solid
        ${(props) => rgba(props.theme.colors.text.default, 0.125)};

      &.inactive {
        opacity: 0.5;
      }

      @media (max-width: 1024px) {
        padding-top: 6px;
        padding-bottom: 8px;
      }
    `}
  >
    <div
      css={`
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.text.default};
      `}
    >
      <div style={{ ...ellipsis() }}>{heading}</div>
    </div>
    <div
      css={`
        margin-top: 2px;
        font-size: 12px;
        letter-spacing: 0.05em;
        color: ${(props) => props.theme.colors.text.default};
      `}
    >
      <div style={{ ...ellipsis() }}>{subheading}</div>
    </div>
  </div>
);

function Header({
  currentDay,
  days,
  headerRef,
  illustrations,
  inactiveBehaviour,
  setCurrentDay,
  scheduleMode,
  stages,
}) {
  return (
    <>
      <div>
        <div
          css={`
            position: relative;
            white-space: nowrap;
            background-color: ${(props) => props.theme.colors.brand.secondary};
            @media (max-width: 1024px) {
              padding-left: 0px;
            }
          `}
          id="scheduleHeader"
        >
          <div
            css={`
              transition: transform 300ms ease-in-out;
              @media (min-width: 1025px) {
                transform: translateX(0) !important;
              }
            `}
            style={{
              transform:
                "translateX(-" +
                days
                  .map(function (e) {
                    return e.date;
                  })
                  .indexOf(currentDay) *
                  100 +
                "%)",
            }}
          >
            {days.map((day, index) => (
              <Pill
                heading={day.label}
                value={day.date}
                date={day.day}
                key={"dayHeader" + index}
                currentDay={currentDay}
                setCurrentDay={setCurrentDay}
                illustrations={illustrations}
                index={index}
                width={100 / days.length}
              />
            ))}
          </div>
          {days[0] && currentDay !== days[0].date && (
            <ArrowLeft
              onClick={() => {
                setCurrentDay(
                  days[
                    days
                      .map(function (e) {
                        return e.date;
                      })
                      .indexOf(currentDay) - 1
                  ].date
                );
              }}
            />
          )}
          {days[0] && currentDay !== days[days.length - 1].date && (
            <ArrowRight
              onClick={() => {
                setCurrentDay(
                  days[
                    days
                      .map(function (e) {
                        return e.date;
                      })
                      .indexOf(currentDay) + 1
                  ].date
                );
              }}
            />
          )}
        </div>
        {scheduleMode === "Full Schedule" && stages && (
          <div
            css={`
              position: relative;
              height: 63px;
              @media (max-width: 1024px) {
                height: 49px;
              }
            `}
          >
            <div
              css={`
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                padding-left: 64px;
                white-space: nowrap;
                background-color: ${(props) => props.theme.colors.bg.default};
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
              `}
              ref={headerRef}
            >
              {stages
                .sort((a, b) => {
                  return a.stage_number - b.stage_number;
                })
                .map((stage, index) => {
                  if (!stage.inactive || inactiveBehaviour !== "Hide") {
                    return (
                      <ColumnHeader
                        heading={
                          stage.stage_name &&
                          PrismicRichText.asText(stage.stage_name)
                        }
                        subheading={
                          stage.stage_subname
                            ? stage.stage_subname
                            : stage.stage_number === 0
                            ? "MAIN"
                            : `Stage ` + stage.stage_number
                        }
                        inactive={stage.inactive}
                        key={"stageHeader" + index}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default Header;
