import React, { useState, useRef, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { graphql } from "gatsby";
import moment from "moment-timezone";
import { RichText as PrismicRichText } from "prismic-reactjs";
import { mdiDownload } from "@mdi/js";
import { lighten, rgba } from "polished";

import { useSchedule } from "src/contexts/schedule";

import {
  SEO,
  Theme,
  PageHeaderV2,
  Box,
  RadioButton,
  FlexRow,
  Button,
  Checkbox,
  SectionWrapper,
  RichText,
  FlexCol,
  PrismicLink,
} from "src/components";

import Header from "./_components/_header";
import Schedule from "./_components/_schedule";
import Personal from "./_components/_personal";
import Print from "./_components/_print";

const scheduleOptions = [
  { value: "Full Schedule", label: "Full Schedule" },
  // TEMP CHANGE FOR SUMMER SERENADES
  // { value: "My Schedule", label: "My Schedule" },
];

const dayLabels = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function ScheduleInner({
  activeFestival,
  festival,
  dataStages,
  setInvert,
  setInvertLogo,
  preview,
}) {
  const {
    schedule,
    removeSchedule,
    notificationsActive,
    setNotificationsActive,
  } = useSchedule();
  const gridRef = useRef(null);
  const headerRef = useRef(null);
  const [stages, setStages] = useState([]);
  const [clickStart, setClickStart] = useState(undefined);
  const [gridStart, setGridStart] = useState(undefined);
  const [shortestTime, setShortestTime] = useState(60);

  const [sortedSchedule, setSortedSchedule] = useState([]);
  const [currentDay, setCurrentDay] = useState(undefined);
  const [scheduleMode, setScheduleMode] = useState("Full Schedule");

  const [days] = useState(() => {
    let days = [];
    festival.schedule.forEach((scheduleItem) => {
      if (scheduleItem.primary.start_time) {
        let date = moment(scheduleItem.primary.start_time)
          .tz("America/Edmonton")
          .dayOfYear();
        let day =
          dayLabels[
            moment(scheduleItem.primary.start_time).tz("America/Edmonton").day()
          ];
        let found = false;
        days.forEach((day) => {
          if (day.date === date) {
            found = true;
          }
        });
        if (!found) {
          days.push({
            label: day,
            day: moment(scheduleItem.primary.start_time)
              .tz("America/Edmonton")
              .format("MMMM DD, YYYY"),
            date: date,
          });
        }
      }
    });
    days = days.sort((a, b) => {
      return a.date - b.date;
    });

    return days;
  });

  const sortSchedule = () => {
    let sortedSchedule = [];
    let stagesPreview = [];
    let shortestTimeSpan = 60;
    if (dataStages) {
      dataStages.edges.forEach((stage) => {
        let stageData = JSON.parse(stage.node.dataString);
        if (
          stageData.festival.uid === festival.uid ||
          (preview && !stageData.festival.uid)
        ) {
          let noEvents = true;
          // Verify this stage has ANY active concerts. If not, prevent it from showing up
          festival.schedule.forEach((scheduleItem, index) => {
            if (
              scheduleItem.primary.stage &&
              scheduleItem.primary.stage.id === stage.node.prismicId
            ) {
              noEvents = false;
            }
          });
          stagesPreview.push({
            ...stageData,
            id: stage.node.prismicId,
            noEvents,
          });
        }
      });
    }
    stagesPreview.forEach((stage) => {
      stage.inactive = true;
      if (stage.stage_name && !stage.noEvents) {
        if (stage.festival || preview) {
          if (
            stage.festival.uid === festival.uid ||
            (preview && !stage.festival.uid)
          ) {
            let stageSchedule = [];
            let earliestTime = 100000;
            let latestTime = 0;
            // Sort schedule items per stage and setup start/end times. As well, measure the shortest time
            festival.schedule.forEach((scheduleItem, index) => {
              if (scheduleItem.primary.stage) {
                if (scheduleItem.primary.start_time) {
                  if (
                    currentDay ===
                      moment(scheduleItem.primary.start_time)
                        .tz("America/Edmonton")
                        .dayOfYear() &&
                    scheduleItem.primary.stage.id === stage.id
                  ) {
                    stage.inactive = false;
                    stageSchedule.push(scheduleItem);
                    let startTime =
                      moment(scheduleItem.primary.start_time)
                        .tz("America/Edmonton")
                        .hour() *
                        60 +
                      moment(scheduleItem.primary.start_time)
                        .tz("America/Edmonton")
                        .minute();

                    let endTime =
                      moment(scheduleItem.primary.end_time)
                        .tz("America/Edmonton")
                        .hour() *
                        60 +
                      moment(scheduleItem.primary.end_time)
                        .tz("America/Edmonton")
                        .minute();

                    let span = endTime - startTime;
                    if (span < shortestTimeSpan) {
                      shortestTimeSpan = span;
                    }

                    if (startTime < earliestTime) {
                      earliestTime = startTime;
                    }
                    if (endTime > latestTime) {
                      latestTime = endTime;
                    }
                  }
                }
              }
            });
            //
            // if (
            //   // festival.inactive_stage_behaviour !== "Hide" ||
            //   stageSchedule.length > 0
            // ) {
            sortedSchedule.push({
              name: PrismicRichText.asText(stage.stage_name),
              number: stage.stage_number,
              schedule: stageSchedule,
              startTime: earliestTime,
              endTime: latestTime,
            });
            // }
          }
        }
      }
    });
    sortedSchedule = sortedSchedule.sort((a, b) => {
      return a.number - b.number;
    });
    setShortestTime(shortestTimeSpan);
    setStages(stagesPreview);
    setSortedSchedule(sortedSchedule);
  };

  useEffect(() => {
    if (days[0] && window.location.search.indexOf("?currentDay=") === -1) {
      setCurrentDay(days[0].date);
    }
  }, [days]);

  //handle setting currentDay as query string, and setting schedule on day change
  useEffect(() => {
    if (currentDay) {
      if (days[0] && days[0].date !== currentDay) {
        window.history.replaceState(
          null,
          null,
          window.location.pathname + "?currentDay=" + currentDay
        );
      } else {
        window.history.replaceState(null, null, window.location.pathname);
      }
      sortSchedule();
    }
  }, [currentDay]);

  useEffect(() => {
    //check if my schedule is open by default
    if (
      window.location.search === "?mySchedule=true" &&
      scheduleMode !== "My Schedule"
    ) {
      setScheduleMode("My Schedule");
    }
    //check if current day is set, and change schedule to that day
    if (window.location.search.indexOf("?currentDay=") !== -1) {
      setCurrentDay(
        parseFloat(window.location.search.replace("?currentDay=", ""))
      );
      setScheduleMode("Full Schedule");
    } else {
      sortSchedule();
    }
  }, []);

  useEffect(() => {
    if (scheduleMode === "My Schedule") {
      window.history.replaceState(
        null,
        null,
        window.location.pathname + "?mySchedule=true"
      );
    } else {
      if (window.location.search.indexOf("?currentDay=") === -1) {
        window.history.replaceState(null, null, window.location.pathname);
      }
    }
  }, [scheduleMode]);

  return (
    <>
      <ThemeProvider
        theme={Theme({
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        <>
          <SEO
            title={"Schedule | " + PrismicRichText.asText(festival.title)}
            description={
              festival.opengraph_description &&
              PrismicRichText.asText(festival.opengraph_description)
            }
            image={
              festival.opengraph_image &&
              festival.opengraph_image.url &&
              festival.opengraph_image.url
            }
            imageAlt={
              festival.opengraph_image &&
              festival.opengraph_image.alt &&
              festival.opengraph_image.alt
            }
          />
          <PageHeaderV2
            heading={
              scheduleMode === "Full Schedule" ? "Schedule" : "My Schedule"
            }
            color="brand.primary"
            invert={true}
            setInvertLogo={setInvertLogo}
            setInvert={setInvert}
          />
        </>
      </ThemeProvider>
      <ThemeProvider
        theme={Theme({
          text:
            festival.theme === "Dark theme"
              ? {
                  default: "#fff",
                  body: rgba("#fff", 0.75),
                  alt: rgba("#fff", 0.5),
                  reverse: "#1B1A19",
                  reverseBody: rgba("#1B1A19", 0.75),
                  reverseAlt: rgba("#1B1A19", 0.5),
                }
              : undefined,
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        <>
          {((activeFestival.ongoing_festival &&
            activeFestival.ongoing_festival.uid !== festival.uid) ||
            activeFestival.festival_online === "No") && (
            <SectionWrapper
              id="ticketsNotAvailable"
              maxWidth={2}
              py={[8, 9, 10]}
              bg="bg.default"
            >
              {activeFestival.festival_offline_message &&
                activeFestival.festival_offline_message[0] && (
                  <Box mb={4} maxWidth={600} mx="auto">
                    <RichText textAlign="center" fontSize={3}>
                      {activeFestival.festival_offline_message &&
                        PrismicRichText.asText(
                          activeFestival.festival_offline_message
                        )}
                    </RichText>
                  </Box>
                )}
              {activeFestival.festival_offline_button_text &&
                activeFestival.festival_offline_button_link &&
                activeFestival.festival_offline_button_link.link_type && (
                  <FlexCol alignItems="center">
                    <Button
                      children={activeFestival.festival_offline_button_text}
                      as={PrismicLink}
                      link={activeFestival.festival_offline_button_link}
                      variant={"primary"}
                    />
                  </FlexCol>
                )}
            </SectionWrapper>
          )}
          {festival.schedule_image && festival.schedule_image.url && (
            <img src={festival.schedule_image.url} width="100%" height="auto" />
          )}
          {((activeFestival.festival_online === "Yes" &&
            activeFestival.ongoing_festival &&
            activeFestival.ongoing_festival.uid === festival.uid &&
            (!festival.schedule_image || !festival.schedule_image.url)) ||
            (preview &&
              (!festival.schedule_image || !festival.schedule_image.url))) && (
            <>
              <div
                css={`
                  position: relative;
                  z-index: 200;
                  background-color: ${(props) => props.theme.colors.bg.wash};
                `}
              >
                <Box
                  bg="brand.secondary"
                  py={4}
                  px={[5, 6, 7]}
                  id="scheduleSubmenu"
                >
                  <div
                    css={`
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: space-between;
                      @media (max-width: 512px) {
                        flex-direction: column;
                        justify-content: center;
                      }
                    `}
                  >
                    {/*<RadioButton
                      options={scheduleOptions}
                      value={scheduleMode}
                      valueProp="value"
                      labelProp="label"
                      onChange={setScheduleMode}
                    />*/}
                    {scheduleMode !== "My Schedule" &&
                      festival.schedule_pdf_link &&
                      festival.schedule_pdf_link.url && (
                        <div
                          css={`
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            @media (max-width: 512px) {
                              margin-top: 24px;
                              justify-content: space-between;
                            }
                          `}
                        >
                          <Box>
                            <Button
                              children={"Download Schedule"}
                              iconLeft={mdiDownload}
                              as={"a"}
                              href={festival.schedule_pdf_link.url}
                              rel="noopener noreferrer"
                              target="_blank"
                            />
                          </Box>
                        </div>
                      )}
                    {schedule && scheduleMode === "My Schedule" && (
                      <div
                        css={`
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          justify-content: center;
                          @media (max-width: 512px) {
                            margin-top: 24px;
                            justify-content: space-between;
                          }
                        `}
                      >
                        <Checkbox
                          children={`Recieve Text Notifications`}
                          value={notificationsActive}
                          checked={notificationsActive}
                          onClick={() => {
                            setNotificationsActive(!notificationsActive);
                          }}
                        />

                        <Box ml={3}>
                          <Button
                            children={"Logout"}
                            onClick={removeSchedule}
                          />
                        </Box>
                      </div>
                    )}
                  </div>
                </Box>
                <Header
                  inactiveBehaviour={festival.inactive_stage_behaviour}
                  headerRef={headerRef}
                  days={days}
                  stages={stages}
                  currentDay={currentDay}
                  setCurrentDay={setCurrentDay}
                  scheduleMode={scheduleMode}
                  illustrations={festival.illlustrations}
                />
              </div>
              {currentDay && scheduleMode === "Full Schedule" && (
                <Schedule
                  darkMode={festival.theme === "Dark theme" ? true : false}
                  inactiveBehaviour={festival.inactive_stage_behaviour}
                  currentDay={currentDay}
                  festival={festival.uid}
                  headerRef={headerRef}
                  gridRef={gridRef}
                  clickStart={clickStart}
                  setClickStart={setClickStart}
                  gridStart={gridStart}
                  setGridStart={setGridStart}
                  sortedSchedule={sortedSchedule}
                  times={festival.gate_times}
                  shortestTime={shortestTime}
                  primary={festival.festival_color}
                  tertiary={festival.festival_tertiary_color}
                  dividers={festival.dividers}
                />
              )}
              {scheduleMode === "My Schedule" && (
                <>
                  <Personal
                    darkMode={festival.theme === "Dark theme" ? true : false}
                    festival={festival.uid}
                    currentDay={currentDay}
                  />
                  <Print days={days} />
                </>
              )}
            </>
          )}
        </>
      </ThemeProvider>
    </>
  );
}

const SchedulePage = ({ data, setInvert, setInvertLogo, preview }) => {
  let festival = {
    ...JSON.parse(data.festival.dataString),
    uid: data.festival.uid,
  };
  let artists = [];
  if (data.artists) {
    data.artists.edges.forEach((artist) => {
      artists.push({
        ...JSON.parse(artist.node.dataString),
        uid: artist.node.uid,
        id: artist.node.prismicId,
      });
    });
  }
  festival.schedule.forEach((lineupItem) => {
    if (lineupItem.primary.performer) {
      artists.forEach((artist) => {
        if (artist.id === lineupItem.primary.performer.id) {
          lineupItem.performerData = artist;
        }
      });
    }
    if (lineupItem.items && lineupItem.items[0].artist) {
      lineupItem.performerData = [];
      lineupItem.items.forEach((itemArtist) => {
        artists.forEach((artist) => {
          if (artist.id === itemArtist.artist.id) {
            lineupItem.performerData.push(artist);
          }
        });
      });
    }
  });
  let activeFestival = {};
  if (data.activeFestival) {
    activeFestival = JSON.parse(data.activeFestival.dataString);
  }
  festival.prismicId = data.festival.prismicId;

  return (
    <ScheduleInner
      activeFestival={activeFestival}
      festival={festival}
      dataStages={data.stages}
      setInvert={setInvert}
      setInvertLogo={setInvertLogo}
      preview={preview}
    />
  );
};

export default SchedulePage;

export const query = graphql`
  query ($id: String!) {
    activeFestival: prismicActiveFestival {
      dataString
    }
    festival: prismicFestival(id: { eq: $id }) {
      prismicId
      uid
      dataString
    }
    stages: allPrismicStage {
      edges {
        node {
          prismicId
          dataString
        }
      }
    }
    artists: allPrismicArtist {
      edges {
        node {
          prismicId
          uid
          dataString
        }
      }
    }
  }
`;
