import React from "react";
import moment from "moment-timezone";

import {
  SectionWrapper,
  FlexCol,
  Text,
  H2,
  FlexGrid,
  FlexRow,
  H4,
  Button,
  AddToSchedule,
} from "src/components";

import { useSchedule } from "src/contexts/schedule";

import PersonalScheduleItem from "./_personalScheduleItem";
import PrintScheduleItem from "./_printScheduleItem";

function Personal({ darkMode, festival, currentDay }) {
  const { schedule, removeFromSchedule, setNeedsPIN, setRetrieve, setCreate } =
    useSchedule();

  return (
    <FlexCol bg="bg.default">
      {schedule && (
        <>
          {schedule.filter((scheduleItem) => {
            if (
              scheduleItem &&
              moment(scheduleItem.primary.start_time)
                .tz("America/Edmonton")
                .dayOfYear() === currentDay
            ) {
              return true;
            } else {
              return false;
            }
          }).length === 0 && (
            <FlexCol
              py={[5, 6, 7]}
              maxWidth={420}
              mx={"auto"}
              px={[3, 4]}
              textAlign="center"
            >
              <H2 children={"You don't have a schedule for this day."} />
              <Text
                my={[4, 5]}
                children={`Add concerts or sessions to your schedule to see them here!`}
              />
            </FlexCol>
          )}
        </>
      )}
      {schedule &&
        schedule.filter((scheduleItem) => {
          if (
            scheduleItem &&
            moment(scheduleItem.primary.start_time)
              .tz("America/Edmonton")
              .dayOfYear() === currentDay &&
            scheduleItem.performerData
          ) {
            return true;
          } else {
            return false;
          }
        }).length > 0 && (
          <>
            {schedule.map((scheduleItem) => {
              if (
                moment(scheduleItem.primary.start_time)
                  .tz("America/Edmonton")
                  .dayOfYear() === currentDay
              ) {
                return (
                  <PersonalScheduleItem
                    key={
                      scheduleItem.primary.stage.id +
                      moment(scheduleItem.primary.start_time).unix()
                    }
                    festival={festival}
                    heading={
                      scheduleItem.slice_type === "concert"
                        ? scheduleItem.primary.concert_title
                          ? scheduleItem.primary.concert_title
                          : `Concert`
                        : scheduleItem.primary.title
                    }
                    artists={
                      scheduleItem.performerData instanceof Array
                        ? scheduleItem.performerData
                        : [scheduleItem.performerData]
                    }
                    startTime={
                      moment(scheduleItem.primary.start_time)
                        .tz("America/Edmonton")
                        .hour() *
                        60 +
                      moment(scheduleItem.primary.start_time)
                        .tz("America/Edmonton")
                        .minute()
                    }
                    endTime={
                      moment(scheduleItem.primary.end_time)
                        .tz("America/Edmonton")
                        .hour() *
                        60 +
                      moment(scheduleItem.primary.end_time)
                        .tz("America/Edmonton")
                        .minute()
                    }
                    stage={
                      scheduleItem.stage
                        ? (scheduleItem.stage.stage_name &&
                            scheduleItem.stage.stage_name[0].text) +
                          (scheduleItem.stage.stage_number === 0
                            ? ""
                            : ` ⁠— Stage ` + scheduleItem.stage.stage_number)
                        : ""
                    }
                    now={
                      moment().unix() <=
                        moment(scheduleItem.primary.end_time).unix() &&
                      moment().unix() >=
                        moment(scheduleItem.primary.start_time).unix()
                    }
                    past={
                      moment().unix() >
                      moment(scheduleItem.primary.end_time).unix()
                    }
                    // notified={isNotified(scheduleItem)}
                    // toggleNotified={() => {
                    //   toggleNotified(scheduleItem);
                    // }}
                    removeFromSchedule={() => {
                      removeFromSchedule(scheduleItem);
                    }}
                  />
                );
              } else {
                return null;
              }
            })}
            <SectionWrapper bg="bg.default">
              <FlexRow py={4} justifyContent="center" id="printRow">
                <Button
                  variant="primary"
                  children={"Print"}
                  onClick={() => {
                    if (document.queryCommandEnabled("print")) {
                      document.execCommand("print", false, null);
                    } else {
                      window.print();
                    }
                  }}
                />
              </FlexRow>
            </SectionWrapper>
          </>
        )}
      <FlexCol
        py={[5, 6, 7]}
        mx={"auto"}
        px={[3, 4]}
        textAlign="center"
        display={!schedule ? "flex" : "none"}
      >
        <H2 children={"You're not currently signed into a schedule."} />
        <FlexCol py={[5, 6]}>
          <FlexGrid
            gutterX={[6]}
            gutterY={[4]}
            alignItems="center"
            justifyContent="center"
          >
            <FlexCol width={[1, 1, 1 / 2]} maxWidth={420}>
              <H4 children={"Create a schedule"} mb={3} />
              <Text
                children={`Ready to build your personalize schedule? Sign up to get started.`}
                mb={3}
              />
              {/* TEMP CHANGE FOR SUMMER SERENADES */}
              {/* <AddToSchedule
                darkMode={darkMode}
                trigger={
                  <Button
                    children={`Create schedule`}
                    variant="primary"
                    onClick={() => {
                      setRetrieve(false);
                      setNeedsPIN(false);
                      setCreate(true);
                    }}
                  />
                }
              /> */}
            </FlexCol>
            <FlexCol width={[1, 1, 1 / 2]} maxWidth={420}>
              <H4 children={"Access a schedule"} mb={3} />
              <Text
                children={`Already created a schedule? Sign in now.`}
                mb={3}
              />
              {/* TEMP CHANGE FOR SUMMER SERENADES */}
              {/* <AddToSchedule
                darkMode={darkMode}
                trigger={
                  <Button
                    children={`Access schedule`}
                    variant="primary"
                    onClick={() => {
                      setCreate(false);
                      setNeedsPIN(false);
                      setRetrieve(true);
                    }}
                  />
                }
              /> */}
            </FlexCol>
          </FlexGrid>
        </FlexCol>
      </FlexCol>
    </FlexCol>
  );
}

export default Personal;
