import React from "react";
import moment from "moment-timezone";

import { useSchedule } from "src/contexts/schedule";

import PrintScheduleItem from "./_printScheduleItem";

function Print({ days }) {
  const { schedule } = useSchedule();

  if (days && schedule) {
    return (
      <div
        css={`
          display: none;
          @media print {
            display: block;
          }
        `}
      >
        {days.map(day => (
          <>
            {schedule &&
              schedule.filter(scheduleItem => {
                if (
                  scheduleItem &&
                  moment(scheduleItem.primary.start_time)
                    .tz("America/Edmonton")
                    .dayOfYear() === day.date &&
                  scheduleItem.performerData
                ) {
                  return true;
                } else {
                  return false;
                }
              }).length > 0 && (
                <>
                  <h1
                    css={`
                      margin-top: 2cm;
                    `}
                  >
                    {day.day}
                  </h1>
                  {schedule.map(scheduleItem => {
                    if (
                      moment(scheduleItem.primary.start_time)
                        .tz("America/Edmonton")
                        .dayOfYear() === day.date
                    ) {
                      return (
                        <PrintScheduleItem
                          key={
                            scheduleItem.primary.stage.id +
                            moment(scheduleItem.primary.start_time).unix()
                          }
                          heading={
                            scheduleItem.slice_type === "concert"
                              ? scheduleItem.primary.concert_title
                                ? scheduleItem.primary.concert_title
                                : `Concert`
                              : scheduleItem.primary.title
                          }
                          artists={
                            scheduleItem.performerData instanceof Array
                              ? scheduleItem.performerData
                              : [scheduleItem.performerData]
                          }
                          startTime={
                            moment(scheduleItem.primary.start_time)
                              .tz("America/Edmonton")
                              .hour() *
                              60 +
                            moment(scheduleItem.primary.start_time)
                              .tz("America/Edmonton")
                              .minute()
                          }
                          endTime={
                            moment(scheduleItem.primary.end_time)
                              .tz("America/Edmonton")
                              .hour() *
                              60 +
                            moment(scheduleItem.primary.end_time)
                              .tz("America/Edmonton")
                              .minute()
                          }
                          stage={
                            scheduleItem.stage
                              ? (scheduleItem.stage.stage_name &&
                                  scheduleItem.stage.stage_name[0].text) +
                                (scheduleItem.stage.stage_number === 0
                                  ? ""
                                  : ` ⁠— Stage ` +
                                    scheduleItem.stage.stage_number)
                              : ""
                          }
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </>
              )}
          </>
        ))}
      </div>
    );
  } else {
    return null;
  }
}

export default Print;
