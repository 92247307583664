import React from "react";

import { Text } from "src/components";

function PrintScheduleItem({ artists, heading, startTime, endTime, stage }) {
  return (
    <table
      className={"print-schedule-item"}
      css={`
        display: none;
        @media print {
          display: table;
          width: 670px;
        }
      `}
    >
      <tr
        css={`
          width: 670px;
        `}
      >
        <td
          css={`
            width: 50%;
          `}
        >
          <h6
            children={heading}
            css={`
              margin-top: 16px;
              margin-bottom: 4px;
            `}
          />
          {artists && (
            <p
              css={`
                margin-bottom: 8px;
              `}
            >
              {artists.map((artist, index) => {
                if (artist) {
                  return (
                    <span
                      children={
                        artist.artist_name &&
                        artist.artist_name[0] &&
                        (index > 0 ? ", " : "") + artist.artist_name[0].text
                      }
                      key={"artistName" + index}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </p>
          )}
        </td>
        <td
          css={`
            width: 50%;
            float: right;
          `}
        >
          <p>
            {(startTime / 60 >= 13
              ? Math.floor(startTime / 60) - 12
              : Math.floor(startTime / 60)) +
              ":" +
              (startTime - Math.floor(startTime / 60) * 60 < 10
                ? "0" + (startTime - Math.floor(startTime / 60) * 60)
                : startTime - Math.floor(startTime / 60) * 60) +
              (startTime / 60 >= 12 ? " PM" : " AM")}
            {` — `}
            {(endTime / 60 >= 13
              ? Math.floor(endTime / 60) - 12
              : Math.floor(endTime / 60)) +
              ":" +
              (endTime - Math.floor(endTime / 60) * 60 < 10
                ? "0" + (endTime - Math.floor(endTime / 60) * 60)
                : endTime - Math.floor(endTime / 60) * 60) +
              (endTime / 60 >= 12 ? " PM" : " AM")}
          </p>
          <p children={stage} />
        </td>
      </tr>
    </table>
  );
}

export default PrintScheduleItem;
