import React, { useEffect, useState } from "react";
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js";
import { Link } from "gatsby";
import moment from "moment-timezone";
import { cover, rgba } from "polished";

import { H6, AddToSchedule, Icon } from "src/components";

import { useSchedule } from "src/contexts/schedule";

const Divider = ({ INCREMENT_HEIGHT, startTime, firstHour, text }) => (
  <H6
    css={`
      z-index: 10;
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      top: calc(${INCREMENT_HEIGHT} * ((${startTime} / 60) - ${firstHour}));
      height: calc(${INCREMENT_HEIGHT} * (0.75));
      text-align: center;
      border-top: 1px solid rgba(0, 0, 0, 0.125);
      background-color: rgba(0, 0, 0, 0.0125);
      span {
        align-self: center;
      }
      &::before {
        content: "";
        ${cover()};
        background-image: repeating-linear-gradient(
          45deg,
          transparent,
          transparent 12px,
          rgba(255, 255, 255, 0.3) 12px,
          rgba(255, 255, 255, 0.3) 13px
        );
      }
    `}
  >
    <span>{text}</span>
  </H6>
);

const GateTime = ({ time }) => (
  <H6
    css={`
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 64px;
      text-align: center;
      border-top: 1px solid rgba(0, 0, 0, 0.125);
      background-color: rgba(0, 0, 0, 0.0125);
      span {
        align-self: center;
      }
      &::before {
        content: "";
        ${cover()};
        background-image: repeating-linear-gradient(
          45deg,
          transparent,
          transparent 12px,
          rgba(0, 0, 0, 0.1) 12px,
          rgba(0, 0, 0, 0.1) 13px
        );
      }
    `}
  >
    <span>{time}</span>
  </H6>
);

const TimeIncrement = ({ INCREMENT_HEIGHT, hour }) => (
  <div
    className="time-increment"
    css={`
      position: relative;
      height: ${INCREMENT_HEIGHT};
      user-select: none;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 1px;
        background-color: ${(props) =>
          rgba(props.theme.colors.text.default, 0.125)};
      }
    `}
  >
    <div
      css={`
        position: relative;
        z-index: 1;
        width: 64px;
        height: 100%;
        padding: 16px;
        font-size: 12px;
        font-variant-numeric: tabular-nums;
        text-align: right;
        color: ${(props) => props.theme.colors.text.default};
      `}
    >
      {hour}
    </div>
  </div>
);

const TimeGrid = ({ INCREMENT_HEIGHT, currentDay, hours }) => (
  <div>
    {hours.map((hour, index) => (
      <TimeIncrement
        hour={hour}
        INCREMENT_HEIGHT={INCREMENT_HEIGHT}
        key={currentDay + "hourincrement" + index}
      />
    ))}
  </div>
);

const Column = ({ children, inactive }) => (
  <div
    className={inactive && "inactive"}
    css={`
      position: relative;
      display: inline-block;
      width: calc(100vw - 64px - 48px);
      max-width: 200px;
      height: 100%;
      margin-right: 16px;
      white-space: initial;
      scroll-snap-align: start;

      &.inactive {
        margin-right: 0px;
        max-width: 216px;
        background-color: rgba(0, 0, 0, 0.05);
      }
    `}
  >
    {children}
  </div>
);

const Tile = ({
  darkMode,
  color,
  festival,
  added,
  firstHour,
  startTime,
  endTime,
  title,
  heading,
  details,
  item,
  externalLink,
  INCREMENT_HEIGHT,
}) => (
  <div
    css={`
      position: absolute;
      top: calc(${INCREMENT_HEIGHT} * ((${startTime} / 60) - ${firstHour}));
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(${INCREMENT_HEIGHT} * ((${endTime} - ${startTime}) / 60));
      padding: 12px 16px 16px;
      border: 1px solid ${(props) => props.theme.colors.bg.alt};
      border-left: 4px solid ${color};
      border-radius: 3px;
      color: ${(props) => props.theme.colors.text.default};
      fill: ${(props) => props.theme.colors.text.default};
      background-color: ${(props) => props.theme.colors.bg.default};
      transition: background-color 300ms ease-in-out;

      &.added {
        background-color: ${(props) => props.theme.colors.brand.secondary};
      }
    `}
    className={added && "added"}
  >
    <div>
      {startTime && endTime && (
        <div
          css={`
            font-size: 14px;
            color: ${(props) => props.theme.colors.text.body};
          `}
        >
          {(startTime / 60 >= 13
            ? Math.floor(startTime / 60) - 12
            : Math.floor(startTime / 60)) +
            ":" +
            (startTime - Math.floor(startTime / 60) * 60 < 10
              ? "0" + (startTime - Math.floor(startTime / 60) * 60)
              : startTime - Math.floor(startTime / 60) * 60) +
            (startTime / 60 >= 12 ? " PM" : " AM")}
          {` – `}
          {(endTime / 60 >= 13
            ? Math.floor(endTime / 60) - 12
            : Math.floor(endTime / 60)) +
            ":" +
            (endTime - Math.floor(endTime / 60) * 60 < 10
              ? "0" + (endTime - Math.floor(endTime / 60) * 60)
              : endTime - Math.floor(endTime / 60) * 60) +
            (endTime / 60 >= 12 ? " PM" : " AM")}
        </div>
      )}
      {title && externalLink && (
        <a href={externalLink} target="_blank" rel="noopener noreferrer">
          <div
            css={`
              margin-top: 8px;
              margin-bottom: 4px;
              font-size: 16px;
              font-weight: 700;
              &:hover {
                text-decoration: underline;
              }
            `}
          >
            {title}
          </div>
        </a>
      )}
      {title && !externalLink && (
        <div
          css={`
            margin-top: 8px;
            margin-bottom: 4px;
            font-size: 16px;
            font-weight: 700;
          `}
        >
          {title}
        </div>
      )}
      {details && (
        <div
          css={`
            margin-top: 8px;
            font-size: 16px;
            font-weight: ${details instanceof Array ? "" : 700};
          `}
        >
          {!(details instanceof Array) && (
            <Link
              to={"/" + festival + "/artist/" + details.uid}
              css={`
                &:hover {
                  text-decoration: underline;
                }
              `}
            >
              {details.artist_name &&
                details.artist_name[0] &&
                details.artist_name[0].text}
            </Link>
          )}
          {details instanceof Array && (
            <>
              {details.map((artist, index) => (
                <Link
                  key={heading + artist + index}
                  to={"/" + festival + "/artist/" + artist.uid}
                  css={`
                    &:hover {
                      text-decoration: underline;
                    }
                  `}
                >
                  {artist.artist_name &&
                    artist.artist_name[0] &&
                    artist.artist_name[0].text &&
                    artist.artist_name[0].text}
                  {index !== details.length - 1 && `, `}
                </Link>
              ))}
            </>
          )}
        </div>
      )}
      {heading && (
        <div
          css={`
            margin-top: 4px;
            font-size: 16px;
            color: ${(props) => props.theme.colors.text.alt};
          `}
        >
          {heading}
        </div>
      )}
    </div>
    {/* TEMP CHANGE FOR SUMMER SERENADES */}
    {/* <div
      css={`
        margin-top: auto;
      `}
    >
      <AddToSchedule item={item} darkMode={darkMode} />
    </div> */}
  </div>
);

const ScheduleArrows = ({ scroll, gridRef, scrollDif }) => (
  <>
    <div
      css={`
        position: fixed;
        z-index: 1;
        top: calc(50% - 28px);
        left: 0;

        white-space: nowrap;
        background-color: black;
        padding: 12px;
        cursor: pointer;

        @media (max-width: 1024px) {
          display: none !important;
        }
      `}
      style={{ display: scroll > 0 ? "block" : "none" }}
      onClick={() => {
        if (gridRef && gridRef.current) {
          gridRef.current.scrollLeft -= 200;
        }
      }}
      id="scheduleArrow"
    >
      <Icon
        path={mdiChevronLeft}
        color={"#fff"}
        css={`
          width: 32px !important;
          height: 32px !important;
          vertical-align: top;
        `}
      />
    </div>
    <div
      css={`
        position: fixed;
        z-index: 100;
        top: calc(50% - 28px);
        right: 0;

        white-space: nowrap;
        background-color: black;
        padding: 12px;
        cursor: pointer;

        @media (max-width: 1024px) {
          display: none !important;
        }
      `}
      style={{
        display: scroll < scrollDif ? "block" : "none",
      }}
      onClick={() => {
        if (gridRef && gridRef.current) {
          gridRef.current.scrollLeft += 200;
        }
      }}
    >
      <Icon
        path={mdiChevronRight}
        color={"#fff"}
        css={`
          width: 32px !important;
          height: 32px !important;
          vertical-align: top;
        `}
      />
    </div>
  </>
);

function Schedule({
  inactiveBehaviour,
  festival,
  headerRef,
  gridRef,
  clickStart,
  setClickStart,
  gridStart,
  setGridStart,
  currentDay,
  sortedSchedule,
  times,
  primary,
  tertiary,
  dividers,
  darkMode,
  shortestTime,
}) {
  const [scroll, setScroll] = useState(0);
  const [scrollDif, setScrollDif] = useState(0);
  const [overflowActive, setOverflowActive] = useState(false);
  const [openingGate, setOpeningGate] = useState(undefined);
  const [closingGate, setClosingGate] = useState(undefined);
  const [hours, setHours] = useState([]);
  const [firstHour, setFirstHour] = useState(0);
  const { isInSchedule } = useSchedule();

  // Set the increment height based on the shortest item in the schedule.
  const INCREMENT_HEIGHT =
    (shortestTime > 60 ? "196" : (160 + (60 - shortestTime) * 2).toString()) +
    "px";

  function checkResize() {
    if (gridRef && gridRef.current) {
      setScrollDif(gridRef.current.scrollWidth - gridRef.current.clientWidth);
      if (
        gridRef.current.scrollWidth > gridRef.current.clientWidth &&
        !overflowActive
      ) {
        setOverflowActive(true);
      } else {
        setOverflowActive(false);
      }
    }
  }

  useEffect(() => {
    if (sortedSchedule && sortedSchedule.length !== 0) {
      let largestSize = 0;
      let earliestStart = 1000;
      sortedSchedule.forEach((stage) => {
        if (earliestStart > Math.floor(stage.startTime / 60)) {
          earliestStart = Math.floor(stage.startTime / 60);
        }
      });
      sortedSchedule.forEach((stage) => {
        let scheduleSize = Math.ceil(stage.endTime / 60) - earliestStart;
        if (scheduleSize > largestSize) {
          largestSize = scheduleSize;
        }
      });
      let hours = [];
      for (let i = 0; i < largestSize; i++) {
        let hour = earliestStart + i;
        hours.push(
          (hour > 12 ? hour - 12 : hour) + (hour >= 12 ? " PM" : " AM")
        );
      }
      setFirstHour(earliestStart);
      setHours(hours);
      if (times) {
        times.forEach((time) => {
          let day = moment(time.day).tz("America/Edmonton").dayOfYear();
          if (day === currentDay) {
            setOpeningGate(time.open);
            setClosingGate(time.close);
          }
        });
      }
    }
    checkResize();
  }, [sortedSchedule]);

  useEffect(() => {
    window.addEventListener("resize", checkResize);
    return function cleanup() {
      window.removeEventListener("resize", checkResize);
    };
  }, []);

  return (
    <>
      {openingGate && <GateTime time={openingGate} />}
      <div
        css={`
          position: relative;
          background-color: ${(props) => props.theme.colors.bg.wash};
        `}
      >
        <TimeGrid hours={hours} INCREMENT_HEIGHT={INCREMENT_HEIGHT} />
        {/* <div
          css={`
            position: absolute;
            left: 54px;
            z-index: 1;
            width: 100%;
            height: 1px;
            pointer-events: none;
            user-select: none;
            &::before {
              content: "";
              position: absolute;
              top: -2px;
              left: 0;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: red;
            }
            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: red;
            }
          `}
          style={{
            top:
              "calc((" +
              INCREMENT_HEIGHT +
              " / 60) * " +
              ((moment()
                .tz("America/Edmonton")
                .hour() -
                firstHour) *
                60 +
                moment()
                  .tz("America/Edmonton")
                  .minute()) +
              ")"
          }}
        >
          <div
            css={`
              position: absolute;
              top: 50%;
              left: -4px;
              font-size: 12px;
              font-weight: 700;
              font-variant-numeric: tabular-nums;
              color: red;
              transform: translateX(-100%) translateY(-50%);
            `}
          >
            {moment()
              .tz("America/Edmonton")
              .format("h:mm")}
          </div>
        </div> */}
        {closingGate && <GateTime time={closingGate} />}
        <div
          ref={gridRef}
          css={`
            ${cover()};
            margin-left: 64px;
            white-space: nowrap;
            overflow-x: scroll;
            overflow-y: hidden;
            cursor: ${clickStart ? "grabbing" : "grab"};
            -webkit-overflow-scrolling: touch;
            user-select: none;

            @media (max-width: 900px) {
              scroll-snap-type: x mandatory;
            }
          `}
          onMouseDown={(event) => {
            setClickStart(event.clientX);
            setGridStart(gridRef.current.scrollLeft);
          }}
          onMouseMove={(event) => {
            if (clickStart) {
              gridRef.current.scrollLeft =
                gridStart + (clickStart - event.clientX);
            }
          }}
          onMouseLeave={() => {
            setClickStart(undefined);
          }}
          onMouseUp={() => {
            setClickStart(undefined);
          }}
          onScroll={(event) => {
            setScroll(event.target.scrollLeft);
            if (headerRef && headerRef.current) {
              if (
                headerRef.current.style.left.replace("px", "") !==
                (-event.target.scrollLeft).toString()
              ) {
                headerRef.current.style.left =
                  "-" + event.target.scrollLeft + "px";
              }
            }
          }}
          id="scheduleGrid"
        >
          {sortedSchedule.map((stage, index) => {
            if (inactiveBehaviour !== "Hide" || stage.schedule.length !== 0) {
              return (
                <Column
                  key={currentDay + "stage" + index}
                  inactive={stage.schedule.length === 0}
                >
                  {stage.schedule.map((scheduleItem, scheduleIndex) => (
                    <Tile
                      INCREMENT_HEIGHT={INCREMENT_HEIGHT}
                      darkMode={darkMode}
                      festival={festival}
                      startTime={
                        moment(scheduleItem.primary.start_time)
                          .tz("America/Edmonton")
                          .hour() *
                          60 +
                        moment(scheduleItem.primary.start_time)
                          .tz("America/Edmonton")
                          .minute()
                      }
                      endTime={
                        moment(scheduleItem.primary.end_time)
                          .tz("America/Edmonton")
                          .hour() *
                          60 +
                        moment(scheduleItem.primary.end_time)
                          .tz("America/Edmonton")
                          .minute()
                      }
                      title={
                        scheduleItem.primary.concert_title
                          ? scheduleItem.primary.concert_title
                          : scheduleItem.primary.title
                      }
                      color={
                        scheduleItem.slice_type === "concert"
                          ? scheduleItem.primary.concert_title
                            ? tertiary
                            : primary
                          : "#fff"
                      }
                      heading={
                        scheduleItem.slice_type === "concert"
                          ? scheduleItem.primary.concert_title
                            ? ""
                            : `Concert`
                          : `Session`
                      }
                      externalLink={
                        scheduleItem.primary.external_link &&
                        scheduleItem.primary.external_link.url &&
                        scheduleItem.primary.external_link.url
                      }
                      details={scheduleItem.performerData}
                      firstHour={firstHour}
                      key={
                        currentDay +
                        "stage" +
                        index +
                        "scheduleItem" +
                        scheduleIndex
                      }
                      item={scheduleItem}
                      added={isInSchedule(scheduleItem) !== -1}
                    />
                  ))}
                </Column>
              );
            } else {
              return null;
            }
          })}
          <div
            css={`
              display: inline-block;
              width: 32px;
            `}
          />
          {dividers &&
            dividers.map(({ divider_time, dividing_text }) => {
              if (
                moment(divider_time).tz("America/Edmonton").dayOfYear() ===
                currentDay
              ) {
                return (
                  <Divider
                    INCREMENT_HEIGHT={INCREMENT_HEIGHT}
                    firstHour={firstHour}
                    startTime={
                      moment(divider_time).tz("America/Edmonton").hour() * 60 +
                      moment(divider_time).tz("America/Edmonton").minute()
                    }
                    text={dividing_text}
                  />
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
      {overflowActive && (
        <ScheduleArrows
          gridRef={gridRef}
          scroll={scroll}
          scrollDif={scrollDif}
        />
      )}
      {/* {closingGate && <GateTime time={closingGate} />} */}
    </>
  );
}

export default Schedule;
