import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import {
  mdiBellOutline,
  mdiBellRingOutline,
  mdiTrashCanOutline
} from "@mdi/js";

import { H6, Icon, IconButton, Text } from "src/components";

const Avatar = ({ image, to }) => (
  <Link
    to={to}
    css={`
      width: 40px;
    `}
  >
    <div
      css={`
        padding-bottom: 100%;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.1);
        background-image: url(${image});
        background-size: cover;
        background-position: 50% 50%;
      `}
    />
  </Link>
);

Avatar.propTypes = {
  image: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

const Pill = () => (
  <div
    css={`
      display: inline-block;
      padding: 4px 8px;
      border-radius: 9999px;
      background-color: ${props => props.theme.colors.brand.primary};
    `}
  >
    <H6 children={`Now`} color="text.reverse" />
  </div>
);

function PersonalScheduleItem({
  festival,
  artists,
  heading,
  startTime,
  endTime,
  stage,
  now,
  past,
  // notified,
  // toggleNotified,
  removeFromSchedule
}) {
  return (
    <div
      className={"personal-schedule-item"}
      css={`
        padding: 24px 7.5vw;
        /* background-color: ${
          past === true ? "rgba(0, 0, 0, 0.1)" : "transparent"
        };
        opacity: ${past === true ? 0.5 : 1}; */
        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <div
        css={`
          display: flex;
          align-items: flex-start;
          width: 100%;
          max-width: 980px;
          margin: 0 auto;
        `}
      >
        <div
          css={`
            flex: 1;
          `}
        >
          {artists && (
            <div
              css={`
                display: flex;
                > *:not(:last-child) {
                  margin-right: 4px;
                }
              `}
            >
              {artists.map((artist, index) => {
                if (artist) {
                  return (
                    <Avatar
                      to={"/" + festival + "/artist/" + artist.uid}
                      image={artist.image && artist.image.third.url}
                      key={"artistImage" + index}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
          )}
          <H6
            children={heading}
            css={`
              margin-top: 16px;
              margin-bottom: 4px;
            `}
          />
          {artists && (
            <Text
              fontSize={2}
              css={`
                margin-bottom: 8px;
              `}
            >
              {artists.map((artist, index) => {
                if (artist) {
                  return (
                    <Link
                      children={
                        artist.artist_name &&
                        artist.artist_name[0] &&
                        (index > 0 ? ", " : "") + artist.artist_name[0].text
                      }
                      to={"/" + festival + "/artist/" + artist.uid}
                      key={"artistName" + index}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </Text>
          )}
          <div
            css={`
              display: flex;
              align-items: center;
              > *:last-child:not(:first-child) {
                margin-left: 8px;
              }
            `}
          >
            <Text color="text.body">
              {(startTime / 60 >= 13
                ? Math.floor(startTime / 60) - 12
                : Math.floor(startTime / 60)) +
                ":" +
                (startTime - Math.floor(startTime / 60) * 60 < 10
                  ? "0" + (startTime - Math.floor(startTime / 60) * 60)
                  : startTime - Math.floor(startTime / 60) * 60) +
                (startTime / 60 >= 12 ? " PM" : " AM")}
              {` — `}
              {(endTime / 60 >= 13
                ? Math.floor(endTime / 60) - 12
                : Math.floor(endTime / 60)) +
                ":" +
                (endTime - Math.floor(endTime / 60) * 60 < 10
                  ? "0" + (endTime - Math.floor(endTime / 60) * 60)
                  : endTime - Math.floor(endTime / 60) * 60) +
                (endTime / 60 >= 12 ? " PM" : " AM")}
            </Text>
            {now && <Pill children={`Now`} />}
          </div>
          <Text children={stage} color="text.body" />
        </div>
        <div
          css={`
            width: 40px;
            margin-left: 16px;
            > *:not(:last-child) {
              margin-bottom: 16px;
            }
          `}
        >
          {/* <IconButton
            icon={notified ? mdiBellRingOutline : mdiBellOutline}
            bg={notified && "brand.primary"}
            color={notified && "text.reverse"}
            onClick={toggleNotified}
          /> */}
          <IconButton
            icon={mdiTrashCanOutline}
            onClick={removeFromSchedule}
            bg="brand.primary"
          />
        </div>
      </div>
    </div>
  );
}

PersonalScheduleItem.propTypes = {
  artists: PropTypes.array.isRequired,
  heading: PropTypes.string.isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  stage: PropTypes.string.isRequired,
  past: PropTypes.bool.isRequired
};

PersonalScheduleItem.defaultProps = {
  past: false
};

export default PersonalScheduleItem;
